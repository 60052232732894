import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const reactRoot = document.getElementById("react-root");
const reactRootDataset = reactRoot ? reactRoot.dataset : {};

// Use default values if the dataset is not available
const firebaseConfig = {
  apiKey: reactRootDataset.firebaseApiKey || 'default-api-key',
  authDomain: reactRootDataset.firebaseAuthDomain || 'default-auth-domain',
  projectId: reactRootDataset.firebaseProjectId || 'default-project-id',
  storageBucket: reactRootDataset.firebaseStorageBucket || 'default-storage-bucket',
  messagingSenderId: reactRootDataset.firebaseMessagingSenderId || 'default-messaging-sender-id',
  appId: reactRootDataset.firebaseAppId || 'default-app-id',
  measurementId: reactRootDataset.firebaseMeasurementId || 'default-measurement-id',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Add this new function to fetch user data
async function getUserData(userId) {
  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      console.log("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}

export { app, db, auth, storage, getUserData };

export const getMultipleUsersData = async (userIds) => {
  console.log('getMultipleUsersData called with:', userIds);
  const db = getFirestore();
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('__name__', 'in', userIds));

  try {
    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('Users fetched:', users);
    return users;
  } catch (error) {
    console.error('Error in getMultipleUsersData:', error);
    throw error;
  }
};
